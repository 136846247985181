import { _User } from "@/components/core/Classes/Interface/User";

/**
 * Checks if user is unauthed
 * @returns true or false
 */
export const isUserAuthed = (AuthUser) => {
  if (AuthUser.id) {
    return true;
  } else {
    return false;
  }
};

/**
 * Checks if user is ADMINISTRATOR
 * @returns true or false
 */
export const isUserAdmin = (AuthUser) => {
  if (AuthUser.claims?.role !== "ADMINISTRATOR") {
    return false;
  } else {
    return true;
  }
};

/**
 * Checks if user is EMPLOYEE
 * @returns true or false
 */
export const isUserEmployee = (AuthUser) => {
  if (AuthUser.claims?.role !== "EMPLOYEE") {
    return false;
  } else {
    return true;
  }
};

/**
 * Checks if user is CUSTOMER
 * @returns true or false
 */
export const isUserCustomer = (AuthUser) => {
  if (AuthUser.claims?.role !== "CUSTOMER") {
    return false;
  } else {
    return true;
  }
};

/**
 * Checks if user is SUPPLIER
 * @returns true or false
 */
export const isUserSupplier = (AuthUser) => {
  if (AuthUser.claims?.role !== "SUPPLIER") {
    return false;
  } else {
    return true;
  }
};

/**
 * Checks if user is KOLLA_CUSTOMER
 * @returns true or false
 */
export const isUserKollaCustomer = (AuthUser) => {
  if (AuthUser.claims?.role !== "KOLLA_CUSTOMER") {
    return false;
  } else {
    return true;
  }
};

/**
 * Checks if user is KOLLA_AGENT
 * @returns true or false
 */
export const isUserKollaAgent = (AuthUser) => {
  if (AuthUser.claims?.role !== "KOLLA_AGENT") {
    return false;
  } else {
    return true;
  }
};

/**
 * Checks if user is KOLLA_ACCOUNTING
 * @returns true or false
 */
export const isUserKollaAccounting = (AuthUser) => {
  if (AuthUser.claims?.role !== "KOLLA_ACCOUNTING") {
    return false;
  } else {
    return true;
  }
};


/**
 * Checks if user is KOLLA generic user
 * @returns true or false
 */
export const isUserKolla = (user : _User) => {
  if (user.Tipologia.includes("KOLLA")) {
    return true;
  } else {
    return false;
  }
};